import { forEach } from "lodash";
import apiFactory from "../../api/apiFactory";
const GroupApi = apiFactory.get("groups");

// initial state
const state = () => ({
  discoverGroups: [],
  userGroups: [],
  groupDetail: {},
});

// getters
const getters = {
  getDiscoverGroups: (state) => state.discoverGroups,
  getUserGroups: (state) => state.userGroups,
  getGroupDetail: (state) => state.groupDetail,
};

// actions
const actions = {
  downloadAllGroups({ commit }, data) {
    return new Promise((resolve, reject) => {
      console.log("Downloading all groups");
      GroupApi.getAllGroups(data.networkId)
        .then((res) => {
          let resData = res.data.data;
          console.log("all groups:", res.data.data);
          commit("setGroups", { all: resData.all, me: resData.me });
          resolve(data);
        })
        .catch((err) => {});
    });
  },
  downloadGroupDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      GroupApi.getGroupDetail(data.networkId, data.groupId)
        .then((res) => {
          let resData = res.data.data;
          console.log("detail group:", res.data.data);
          commit("setGroupDetail", {
            detail: resData.group,
          });
          commit(
            "social/setPostsInActiveNetwork",
            { posts: resData.posts },
            { root: true }
          );
          resolve("detail group downloaded");
        })
        .catch((err) => {});
    });
  },
  createNewGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      GroupApi.createNewGroup(data.networkId, data.name, data.img)
        .then((res) => {
          let resData = res.data.data;
          console.log("Created Event:", resData, res);
          resolve(resData);
        })
        .catch((err) => {
          console.log("ERR", err);
          reject(err);
        });
    });
  },
  setMembership({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      GroupApi.setMembership(data.networkId, data.groupId)
        .then((res) => {
          let resData = res.data.data;
          dispatch("downloadAllGroups", { networkId: data.networkId });
          dispatch("downloadGroupDetail", {
            networkId: data.networkId,
            groupId: data.groupId,
          });
          resolve(resData);
        })
        .catch((err) => {});
    });
  },
  reciveNewGroup({ commit, dispatch, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      commit("setNewGroup", { group: data.group, rootGetters: rootGetters });
      resolve();
    });
  },
};

// mutations
const mutations = {
  setGroups(state, payload) {
    state.discoverGroups = payload.all;
    state.userGroups = payload.me;
  },
  setGroupDetail(state, payload) {
    state.groupDetail = payload.detail;
  },
  setNewGroup(state, payload) {
    if (payload.group.user_id != payload.rootGetters["auth/getUserId"]) {
      state.discoverGroups.unshift(payload.group);
    } else {
      state.userGroups.unshift(payload.group);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
