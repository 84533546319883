import apiFactory from "../../api/apiFactory";
const AuthApi = apiFactory.get("auth");
const BaseApi = apiFactory.get("base");

// initial state
const state = () => ({
  anonymous: true,
  email: "",
  avatarId: -1,
  avatarUrl: "",
  id: 0,
  name: "",
});

// getters
const getters = {
  isLoggedIn: (state) => state.email,
  getUserName: (state) => state.name,
  getUserId: (state) => state.id,
  isFullySettedUp: (state) => {
    // debugger;
    if (
      state.email.length > 0 &&
      state.avatarId != -1 &&
      state.name != null &&
      state.name.length > 0
    ) {
      return true;
    }
    return false;
  },
  getUserAvatarUrl: (state) => state.avatarUrl,
  getUserAvatarId: (state) => state.avatarId,
};

// actions
const actions = {
  async login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (data.pass.length == 0) {
        data.pass = data.email.split("@")[0];
      }

      AuthApi.login(data.email, data.pass)
        .then((res) => {
          let resUserData = res.data.user;
          dispatch(
            "websocket/websocketInit",
            { jwt: res.data.token },
            { root: true }
          );
          commit("setUserInfo", {
            avatarId: resUserData.avatar_id,
            avatarUrl: resUserData.avatar_url,
            name: resUserData.name,
            id: resUserData.id,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async registerAnonym({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      let randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomizedString = "";
      for (let i = 0; i < 32; i++) {
        randomizedString += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      let email = randomizedString + "@sosi.cz";
      let pass = randomizedString;

      AuthApi.register(email, pass, 1).then((res) => {
        commit("setEmail", { email: email });
        dispatch("login", { email: email, pass: pass })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
  async updateUserInfo({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      console.log("update", data);
      AuthApi.update(data.email, data.name, data.avatar).then((res) => {
        let resData = res.data.user;

        commit("setUserInfo", {
          name: resData.name,
          avatarId: resData.avatar_id,
          avatarUrl: resData.avatar_url,
          id: resData.id,
        });
        resolve(res);
      });
    });
  },
  async downloadAvatars({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      BaseApi.getAvatarList().then((res) => {
        resolve(res.data.data);
      });
    });
  },
};

// mutations
const mutations = {
  setEmail(state, payload) {
    state.email = payload.email;
  },
  setUserInfo(state, payload) {
    state.avatarId = payload.avatarId;
    state.avatarUrl = payload.avatarUrl;
    state.name = payload.name;
    state.id = payload.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
