import { $api } from "../apiClient";
const pathPrefix = "/auth";

export default {
  register(email, pass, anonymous) {
    let payload = new FormData();

    payload.append("email", email);
    payload.append("password", pass);
    payload.append("password_confirmation", pass);
    // payload.append("is_anonymous", anonymous);

    return $api.post(`${pathPrefix}/register`, payload);
  },
  login(email, pass) {
    let payload = new FormData();

    payload.append("login", email);
    payload.append("password", pass);

    return $api.post(`${pathPrefix}/login`, payload);
  },
  update(email = "", name = "", avatar_id = 0) {
    let payload = new FormData();
    if (email.length > 0) {
      payload.append("email", email);
    }
    if (name.length > 0) {
      payload.append("name", name);
    }
    if (avatar_id > 0) {
      payload.append("avatar_id", avatar_id);
    }

    return $api.post(`${pathPrefix}/update-info`, payload);
  },
};
