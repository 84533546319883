import apiFactory from "../../api/apiFactory";
import _ from "lodash";

// initial state
const state = () => ({
  newPostCount: 0,
  newMsgCount: 0,
  newGroupCount: 0,
  newEventCount: 0,
});

// getters
const getters = {
  getNewPostCount: (state) => state.newPostCount,
  getNewMsgCount: (state) => state.newMsgCount,
  getNewGroupCount: (state) => state.newGroupCount,
  getNewEventCount: (state) => state.newEventCount,
};

// actions
const actions = {
  changeCounter({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      switch (data.action) {
        case "add":
          commit("addToCounter", { type: data.type });
          break;
        case "reset":
          commit("resetCounter", { type: data.type });
          break;
      }
      resolve(data.action);
    });
  },
};

// mutations
const mutations = {
  addToCounter(state, payload) {
    switch (payload.type) {
      case "newPost":
        state.newPostCount++;
        break;
      case "newMsg":
        state.newMsgCount++;
        break;
      case "newGroup":
        state.newGroupCount++;
        break;
      case "newEvent":
        state.newEventCount++;
        break;
    }
  },
  resetCounter(state, payload) {
    switch (payload.type) {
      case "newPost":
        state.newPostCount = 0;
        break;
      case "newMsg":
        state.newMsgCount = 0;
        break;
      case "newGroup":
        state.newGroupCount = 0;
        break;
      case "newEvent":
        state.newEventCount = 0;
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
