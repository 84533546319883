import { $api } from "../apiClient";
const pathPrefix = "/network/chat";

export default {
  getAllUsers(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/users`, payload);
  },
  getAllChats(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/all`, payload);
  },
  getChatDetail(network_id, chat_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("chat_id", chat_id);
    return $api.post(`${pathPrefix}/detail`, payload);
  },
  createNewChat(network_id, user) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    // if (user.length > 1) {
    user.forEach((element) => {
      payload.append("user[]", element);
    });
    // } else {
    //   payload.append("user", user);
    // }

    return $api.post(`${pathPrefix}/open`, payload);
  },
  sendMessage(network_id, chat_id, text) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("chat_id", chat_id);
    payload.append("text", text);
    return $api.post(`${pathPrefix}/send-message`, payload);
  },
  addUserToChat(network_id, chat_id, user) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("chat_id", chat_id);
    payload.append("user", user);
    return $api.post(`${pathPrefix}/add-user`, payload);
  },
};
