import { $api } from "../apiClient";
const pathPrefix = "/network/group";

export default {
  getAllGroups(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/all`, payload);
  },
  getGroupDetail(network_id, group_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("group_id", group_id);
    return $api.post(`${pathPrefix}/detail`, payload);
  },
  async createNewGroup(network_id, name, img) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("name", name);

    // let imageBlob = await new Promise((resolve) =>
    //   image.toBlob(resolve, "image/png")
    // );

    // payload.append("image", imageBlob, "image.png");

    payload.append("image", img);

    return $api.post(`${pathPrefix}/create`, payload);
  },
  setMembership(network_id, group_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("group_id", group_id);

    return $api.post(`${pathPrefix}/add-remove`, payload);
  },
};
