import { $api } from "../apiClient";
const pathPrefix = "";

export default {
  getQuizList() {
    return $api.get(`${pathPrefix}/answersheets`);
  },
  getQuizDetail(id) {
    let payload = new FormData();
    payload.append("answer_sheet_id", id);

    return $api.post(`${pathPrefix}/answersheet`, payload);
  },
  getAvatarList() {
    return $api.post(`${pathPrefix}/avatars`);
  },
};
