<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="quiz" href="/tabs/quizlist">
          <ion-icon :icon="homeOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button
          :disabled="getActiveId == 0 ? true : false"
          tab="social"
          :href="`/tabs/social/${getActiveId}`"
        >
          <ion-icon :icon="globeOutline"></ion-icon>
        </ion-tab-button>
        <ion-tab-button tab="menu" href="/tabs/menu">
          <ion-icon :icon="listOutline"></ion-icon>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonIcon,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
} from "@ionic/vue";
import { homeOutline, globeOutline, listOutline } from "ionicons/icons";
import { mapGetters } from "vuex";

export default {
  name: "Creation",
  components: {
    IonIcon,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      homeOutline,
      globeOutline,
      listOutline,
    };
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
    }),
  },
};
</script>
