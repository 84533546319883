import apiFactory from "../../api/apiFactory";
const ChatApi = apiFactory.get("chat");

// initial state
const state = () => ({
  listOfUsers: [],
  listOfChats: [],
  chatDetailId: 0,
  chatDetailUsers: [],
  chatDetailMessages: [],
});

// getters
const getters = {
  getListOfUsers: (state) => state.listOfUsers,
  getListofChats: (state) => state.listOfChats,
  getChatDetailUsers: (state) => state.chatDetailUsers,
  getChatDetailMessages: (state) => state.chatDetailMessages,
  getChatDetailid: (state) => state.chatDetailId,
};

// actions
const actions = {
  downloadAllUsers({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.getAllUsers(data.id).then((res) => {
        commit("setAllUsers", { users: res.data.data.users });
        resolve(res.data.data);
      });
    });
  },
  downloadAllChats({ commit }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.getAllChats(data.networkId).then((res) => {
        // console.log("Chat Created.", res.data);
        commit("setChatList", { chats: res.data.data });
        resolve(res.data.data);
      });
    });
  },
  downloadChatDetail({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.getChatDetail(data.networkId, data.chatId).then((res) => {
        console.log("Chat detail downloaded.", res.data);
        commit("setChatDetail", { chat: res.data.data });
        resolve(res.data.data);
      });
    });
  },
  createNewChat({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.createNewChat(data.network, data.users).then((res) => {
        console.log("Chat Created.", res.data);
        resolve(res.data.data);
      });
    });
  },
  addUserToChat({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.addUserToChat(data.networkId, data.chatId, data.users).then(
        (res) => {
          resolve(res.data.data);
        }
      );
    });
  },
  sendMessage({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      ChatApi.sendMessage(data.networkId, data.chatId, data.text).then(
        (res) => {
          resolve(res.data.data);
        }
      );
    });
  },
  reciveMessage({ commit, getters }, data) {
    if (getters.getChatDetailid == data.message.chat_group_id) {
      commit("addMessage", { message: data.message });
    }
  },
  setChatDetailId({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setChatId", { id: data.id });
      resolve(data.id);
    });
  },
  reciveNewChat({ commit, rootGetters }, data) {
    if (Object.values(data.members).includes(rootGetters["auth/getUserId"])) {
      commit("pushNewChat", { chat: data.chat });
    }
  },
  resetChatListData({ commit }) {
    commit("resetChatList");
  },
};

// mutations
const mutations = {
  setAllUsers(state, payload) {
    state.listOfUsers = payload.users;
  },
  setChatList(state, payload) {
    state.listOfChats = payload.chats;
  },
  setChatDetail(state, payload) {
    state.chatDetailUsers = payload.chat.user;
    state.chatDetailMessages = payload.chat.messages;
  },
  addMessage(state, payload) {
    state.chatDetailMessages.push(payload.message);
  },
  setChatId(state, payload) {
    state.chatDetailId = payload.id;
  },
  pushNewChat(state, payload) {
    state.listOfChats.unshift(payload.chat);
  },
  resetChatList(state, payload) {
    state.listOfUsers = [];
    state.listOfChats = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
