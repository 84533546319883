import { createRouter, createWebHistory } from "@ionic/vue-router";
import Tabs from "../views/Tabs.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    redirect: "Create",
  },
  {
    path: "/tabs",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "quiz",
      },
      {
        path: "quizlist",
        name: "QuizList",
        component: () => import("@/views/Quizes.vue"),
      },
      {
        name: "socialEnter",
        path: "socialEnter",
        component: () => import("@/views/EnterSocialNetwork.vue"),
      },
      {
        name: "Menu",
        path: "menu",
        component: () => import("@/views/Menu.vue"),
        meta: {
          // isInNetwork: true,
        },
      },
      {
        path: "social/:id",
        component: () => import("@/views/SocialNetwork.vue"),
        meta: {
          // isInNetwork: true,
        },
      },

      // {
      //   path: "geolocation",
      //   component: () => import("@/views/Geolocation.vue"),
    ],
  },
  {
    path: "/chat/:id",
    component: () => import("@/views/ChatDetail.vue"),
  },
  {
    path: "/event/:id",
    component: () => import("@/views/EventDetail.vue"),
  },
  {
    path: "/group/:id",
    component: () => import("@/views/GroupDetail.vue"),
  },
  {
    name: "Create",
    path: "/create",
    component: () => import("@/views/Creation.vue"),
    meta: {
      isAlreadyCreated: true,
    },
  },
  {
    name: "Quiz",
    path: "/quiz/:id",
    component: () => import("@/views/QuizDetail.vue"),
  },
  {
    name: "Questions",
    path: "/questions/:id",
    props: true,
    component: () => import("@/views/QuizContainer.vue"),
  },
  {
    name: "Result",
    path: "/result/:id",
    props: true,
    component: () => import("@/views/Result.vue"),
    meta: {
      hasResult: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/tabs/quizlist",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("ROUTER: isFullySettedUp", store.getters["auth/isFullySettedUp"]);
  console.log("ROUTER: to", to.name, "from", from.name);
  if (to.name != "Create" && !store.getters["auth/isFullySettedUp"]) {
    console.log("ROUTER: redirect to Create");
    next({ name: "Create" });
  } else if (to.matched.some((record) => record.meta.hasResult)) {
    console.log("ROUTER: Has result");
    console.log("hes result", store.getters["question/getResults"]);
    // if (store.getters["question/getResults"] == undefined) {
    //   next({ name: "QuizList" });
    // } else {
    next();
    // }
  } else if (to.matched.some((record) => record.meta.isAlreadyCreated)) {
    console.log("ROUTER: Is already crated?");
    if (!store.getters["auth/isFullySettedUp"]) {
      next();
    } else {
      next({ name: "QuizList" });
    }
  } else {
    next();
  }
});

export default router;
