import { $api } from "../apiClient";
const pathPrefix = "/network";

export default {
  addToNetwork(code) {
    let payload = new FormData();
    payload.append("code", code);
    return $api.post(`${pathPrefix}/add`, payload);
  },
  getSocialDetail(id) {
    let payload = new FormData();
    payload.append("network_id", id);
    return $api.post(`${pathPrefix}/detail`, payload);
  },
  getMyPosts(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/post/me`, payload);
  },
  getListOfSocials() {
    return $api.get(`${pathPrefix}/list`);
  },
  leaveSocial(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/remove`, payload);
  },
  sendComment(network_id, post_id, text) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("post_id", post_id);
    payload.append("text", text);
    return $api.post(`${pathPrefix}/comment/save`, payload);
  },
  sendLikeComment(network_id, comment_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("comment_id", comment_id);

    return $api.post(`${pathPrefix}/comment/like`, payload);
  },
  sendLike(network_id, post_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("post_id", post_id);

    return $api.post(`${pathPrefix}/post/like`, payload);
  },

  sendNewPost(network_id, text, event_id, group_id, img) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("text", text);
    if (event_id != 0) {
      payload.append("event_id", event_id);
    }
    if (group_id != 0) {
      payload.append("group_id", group_id);
    }
    if (img != "" || img != null) {
      payload.append("image", img);
    }
    // debugger;
    return $api.post(`${pathPrefix}/post/save`, payload);
  },
  updateProfile(network_id, name, avatar_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    if (name != null) {
      payload.append("name", name);
    }
    if (avatar_id != null) {
      payload.append("avatar_id", avatar_id);
    }
    // debugger;
    return $api.post(`${pathPrefix}/user/update`, payload);
  },
};
