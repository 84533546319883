import { $api } from "../apiClient";
const pathPrefix = "";

export default {
  getAllQuestions(answer_sheet_id) {
    let payload = new FormData();
    payload.append("answer_sheet_id", answer_sheet_id);

    return $api.post(`${pathPrefix}/topic-questions`, payload);
  },
  saveAnwser(topic_id, question_id, value, correctly) {
    let payload = new FormData();
    payload.append("topic_id", topic_id);
    payload.append("question_id", question_id);
    payload.append("value", JSON.stringify(value));
    payload.append("correctly", correctly);

    return $api.post(`${pathPrefix}/result`, payload);
  },
  endQuestions(topic_id) {
    let payload = new FormData();
    payload.append("topic_id", topic_id);
    return $api.post(`${pathPrefix}/end-answersheet`, payload);
  },
};
