import _ from "lodash";

import apiFactory from "../../api/apiFactory";
const QuestionsApi = apiFactory.get("questions");

// initial state
const state = () => ({
  isLoaded: false,
  allQuestion: {},
  currentQuestion: [],
  currentState: "",
  currentQuestionIndex: 0,
  results: {},
});

// getters
const getters = {
  getCurrentState: (state) => state.currentState,
  getCurrentQuestion: (state) => state.currentQuestion,
  getCurrentQuestionIndex: (state) => state.currentQuestionIndex,
  getIsLoaded: (state) => state.isLoaded,
  getLengthOfQuestions: (state) => Object.keys(state.allQuestion).length,
  getQuestionByIndex: (state) => (index) => {
    return state.allQuestion[index];
  },
  getQuestions: (state) => state.allQuestion,
  getResults: (state) => state.results,
};

// actions
const actions = {
  async downloadAllQuests({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      QuestionsApi.getAllQuestions(data.answerSheetId)
        .then((res) => {
          commit("isLoaded", { isLoaded: false });
          commit("setAllQuestions", { questions: res.data.data });
          let index = 0;
          if (
            data.start != "null" &&
            data.start != undefined &&
            data.start != null
          ) {
            index = _.findIndex(getters.getQuestions, (o) => {
              return o.id == data.start;
            });
            index++;
          }
          commit("setCurrent", {
            index: index,
            data: res.data.data[index],
            state: "inProgress",
          });
          commit("isLoaded", { isLoaded: true });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  newState({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setCurrentState", { state: data.state });
      resolve("stateChanged");
    });
  },
  getNewQuestion({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      let newIndex = getters.getCurrentQuestionIndex + 1;
      let question = getters.getQuestionByIndex(newIndex);
      if (data.jump != null) {
        newIndex = _.findIndex(getters.getQuestions, (o) => {
          return o.id == data.jump;
        });
        question = getters.getQuestionByIndex(newIndex);
      }
      commit("setNewQuestion", { index: newIndex, data: question });
      commit("setCurrentState", { state: "inProgress" });
      // console.log("new question", question, newIndex);
      resolve("questionChanged");
    });
  },
  endQuestions({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      QuestionsApi.endQuestions(data.topicId).then((res) => {
        dispatch("saveResults", { results: res.data });
        resolve(res.data);
      });
    });
  },
  sendResult({ commit }, data) {
    return new Promise((resolve, reject) => {
      QuestionsApi.saveAnwser(
        data.topicId,
        data.questionId,
        data.value,
        data.correctly
      ).then((res) => {
        resolve("saved");
      });
    });
  },
  saveResults({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setResults", { results: data.results });
      resolve("results set");
    });
  },
};

// mutations
const mutations = {
  setAllQuestions(state, payload) {
    state.allQuestion = payload.questions;
  },
  setCurrent(state, payload) {
    state.currentQuestion = payload.data;
    state.currentQuestionIndex = payload.index;
    state.currentState = payload.state;
  },
  isLoaded(state, payload) {
    state.isLoaded = payload.isLoaded;
  },
  setCurrentState(state, payload) {
    state.currentState = payload.state;
  },
  setNewQuestion(state, payload) {
    state.currentQuestionIndex = payload.index;
    state.currentQuestion = payload.data;
  },
  setResults(state, payload) {
    state.results = payload.results;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
