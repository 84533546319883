import axios from "axios";
import { api_url } from "../config/variables";

// ALL DEFUALT CONFIGURATION OF AXIOS HERE

console.log("api_url", api_url);

const axiosConfig = {
  baseURL: api_url,
  timeout: 30000,
  withCredentials: true,
  headers: {},
};

export const $api = axios.create(axiosConfig);

// Global interceptor -> Do something, then pass it to local .then() | .catch() method if needed
$api.interceptors.response.use(
  (response) => response,
  (error) => {
    //do something
    // alert(error);
    console.log("API call error:", error, "| Object:", { error });
    return Promise.reject(error.response.data);
  }
);
