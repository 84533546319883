import apiFactory from "../../api/apiFactory";
const baseApi = apiFactory.get("base");

// initial state
const state = () => ({
  quizArray: [],
  loading: true,
  quizDetail: {},
});

// getters
const getters = {
  getQuizArray: (state) => state.quizArray,
  isLoaded: (state) => !state.loading,
  getDetail: (state) => state.quizDetail,
};

// actions
const actions = {
  async downloadQuizArray({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi
        .getQuizList()
        .then((res) => {
          let quizArray = res.data.data[0].themes;
          console.log("quizArr", res);
          commit("setQuizArray", { quizArray: quizArray });
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
        });
    });
  },
  downloadQuizDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", { loading: true });
      baseApi
        .getQuizDetail(data.id)
        .then((res) => {
          commit("setDetail", { data: res.data.data });
          commit("setLoading", { loading: false });
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  changeLoading({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", { loading: data.laoding });
    });
  },
};

// mutations
const mutations = {
  setQuizArray(state, payload) {
    state.quizArray = payload.quizArray;
  },
  setLoading(state, payload) {
    state.loading = payload.loading;
  },
  setDetail(state, payload) {
    state.quizDetail = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
