import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vuex from "vuex";

import { IonicVue } from "@ionic/vue";

/* AXIOS import */
import axios from "axios";
import VueAxios from "vue-axios";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import VueLazyload from "vue-lazyload";

/* Use api factory */
// import factories from "./api/apiFactory";

import { SplashScreen } from "@capacitor/splash-screen";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(Vuex)
  .use(VueAxios, axios);
// .use(VueLazyload, {
//   preLoad: 1.3,
//   loading: loadimage,
//   attempt: 1
// });

console.log("APP", app);

app.config.globalProperties.$axios = axios;
// app.config.globalProperties.$api = factories;

router.isReady().then(() => {
  app.mount("#app");
  SplashScreen.hide();
});
