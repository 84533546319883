import authFactory from "./modules/auth.api";
import baseFactory from "./modules/base.api";
import socialFactory from "./modules/social.api";
import chatFactory from "./modules/chat.api";
import eventsFactory from "./modules/events.api";
import groupFactory from "./modules/groups.api";
import questionsFactory from "./modules/questions.api";

const factories = {
  auth: authFactory,
  base: baseFactory,
  social: socialFactory,
  chat: chatFactory,
  events: eventsFactory,
  groups: groupFactory,
  questions: questionsFactory,
};
export default {
  get: (name) => factories[name],
};
