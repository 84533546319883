import Pusher from "pusher-js";
import Echo from "laravel-echo";

import { wsConfig } from "../../config/variables";
import { _ } from "core-js";
import { emitter } from "../../eventBus";

// initial state
const state = () => ({
  ws: null,
  pusher: null,
  status: "",
});

// getters
const getters = {
  getSocket: (state) => state.ws,
  getPusher: (state) => state.pusher,
};

// actions
const actions = {
  websocketInit({ commit }, data) {
    return new Promise((resolve, reject) => {
      let socket = new Echo({
        broadcaster: "pusher",
        key: wsConfig.key,
        wsHost: wsConfig.url,
        wsPort: wsConfig.port,
        wssPort: wsConfig.port,
        cluster: wsConfig.cluster,
        authEndpoint: wsConfig.authUrl,
        auth: {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + data.jwt,
          },
        },
        disableStats: true,
        forceTLS: true,
        encrypted: false,
        enabledTransports: ["ws", "wss"],
        // enabledTransports: ["wss"],
      });

      socket.logToConsole = true;

      socket.connector.pusher.connection.bind("connected", () => {
        console.log("WS CONNECTED");
      });

      socket.connector.pusher.connection.bind("error", (err) => {
        console.log("WS ERROR", err);
      });

      socket.connector.pusher.connection.bind("state_change", (states) => {
        console.log("WS STATES CHANGED", states);
        commit("SET_WS_STATUS", { status: states.current });
      });

      socket.connector.pusher.connection.bind("disconnected", () => {
        console.log("WS DISCONECTED");
      });

      commit("SET_WS", { ws: socket });
      resolve("WS loaded");
    });
  },
  connectToNetworkChannel({ commit, dispatch, getters, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      let pusher = getters.getPusher;
      // debugger;
      let channel = pusher.subscribe(`private-network.${data.id}`);

      channel.bind("SavePostEvent", (msg) => {
        //push to posts
        console.log("SavePostEvent", msg);
        dispatch("social/reciveNewPost", { post: msg.post }, { root: true });
        if (msg.post.user_id != rootGetters["auth/getUserId"]) {
          dispatch(
            "notifications/changeCounter",
            { type: "newPost", action: "add" },
            { root: true }
          );
        }
      });

      channel.bind("LikePostEvent", (msg) => {
        //edit posts (like)
        console.log("LikePostEvent", msg);
        dispatch("social/reciveLikePost", { post: msg.post }, { root: true });
      });

      channel.bind("SaveCommentEvent", (msg) => {
        //edit post (comment)
        console.log("SaveCommentEvent", msg);
        dispatch(
          "social/reciveComment",
          { comment: msg.comment, user: msg.user },
          { root: true }
        );
      });

      channel.bind("LikeCommentEvent", (msg) => {
        console.log("LikeCommentEvent", msg);
        dispatch(
          "social/reciveCommentLike",
          { comment: msg.comment },
          { root: true }
        );
      });

      channel.bind("AddToGroupEvent", (msg) => {
        console.log("AddToGroupEvent", msg);
      });

      channel.bind("RemoveToGroupEvent", (msg) => {
        console.log("RemoveToGroupEvent", msg);
      });

      channel.bind("CreateNewGroupEvent", (msg) => {
        console.log("CreateNewGroupEvent", msg);
        dispatch("groups/reciveNewGroup", { group: msg.group }, { root: true });
        if (msg.group.user_id != rootGetters["auth/getUserId"]) {
          dispatch(
            "notifications/changeCounter",
            { type: "newGroup", action: "add" },
            { root: true }
          );
        }
      });

      channel.bind("CreateNewEventEvent", (msg) => {
        console.log("CreateNewEventEvent", msg);
        dispatch("events/reciveNewEvent", { event: msg.event }, { root: true });
        if (msg.event.user_id != rootGetters["auth/getUserId"]) {
          dispatch(
            "notifications/changeCounter",
            { group: msg.group },
            { root: true }
          );
        }
      });
    });
  },
  connectToChatChannel({ dispatch, getters, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      let pusher = getters.getPusher;
      console.log("Connectiong to [private-network_chat." + data.id + "]");

      let channel = pusher.subscribe(`private-network_chat.${data.id}`);

      channel.bind("SendMessageEvent", (msg) => {
        if (msg.user.id != rootGetters["auth/getUserId"]) {
          dispatch(
            "notifications/changeCounter",
            { type: "newMsg", action: "add" },
            { root: true }
          );
          // emitter.emit("notification", {
          //   type: "message",
          //   text: msg.message.text,
          //   from: msg.message.name,
          //   linkId: msg.message.chat_group_id,
          // });
          // emitter.emit("noificationCounter", {
          //   type: "newMsg",
          // });
        }

        console.log("SendMessageEvent", msg);
        let data = "";
        if (msg) {
          data = msg.message;
          dispatch("chat/reciveMessage", { message: data }, { root: true });
        }
      });

      channel.bind("CreateChatGroupEvent", (msg) => {
        console.log("CreateChatGroupEvent", msg);
        dispatch(
          "chat/reciveNewChat",
          { chat: msg.group, members: msg.members },
          { root: true }
        );
      });
    });
  },
  disconnectChannel({ dispatch, getters }, data) {
    return new Promise((resolve, reject) => {
      let pusher = getters.getPusher;
      pusher.unsubscribe(`private-network.${data.id}`);
      pusher.unsubscribe(`private-network_chat.${data.id}`);
    });
  },
};

// mutations
const mutations = {
  SET_WS(state, payload) {
    state.ws = payload.ws;
    state.pusher = payload.ws.connector.pusher;
  },
  SET_WS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
