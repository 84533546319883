import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import quiz from "./modules/quiz";
import social from "./modules/social";
import chat from "./modules/chat";
import websocket from "./modules/websocket";
import events from "./modules/events";
import groups from "./modules/groups";
import questions from "./modules/questions";
import notifications from "./modules/notifications";

export default createStore({
  modules: {
    auth,
    quiz,
    social,
    chat,
    websocket,
    events,
    groups,
    questions,
    notifications,
  },
  plugins: [
    createPersistedState({
      key: "sosi_vuex",
      paths: [
        // "user",
        "auth",
        "social.activeId",
      ],
    }),
  ],
});
