export const api_url = " https://sosi.futurebooks.cz/api";

export const wsConfig = {
  jwt: "xxx",
  port: 6002,
  authUrl: "https://sosi.futurebooks.cz/broadcasting/auth",
  appId: "sosi_network",
  url: "wsocket.futurebooks.cz",
  appSecret: "NUiDcUcWC19AvvaMpWDqA6Kea4uUYa32",
  key: "6QlZJwsRKLHENwe9TdhIlSlYCAOhulHq",
  cluster: "sosi",
};
