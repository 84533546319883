<template>
  <ion-page>
    <div class="container">
      <ion-img
        class="cvut-logo"
        :src="require('@//assets/img/cvut_symbol.svg')"
      >
      </ion-img>
      <ion-spinner color="light"></ion-spinner>
      <ion-img
        class="project-logo"
        :src="require('@//assets/img/logo_projektu.jpg')"
      ></ion-img>
    </div>
  </ion-page>
</template>

<script>
import { IonImg, IonPage, IonSpinner } from "@ionic/vue";
export default {
  name: "LoadingScreen",
  components: { IonImg, IonPage, IonSpinner },
};
</script>

<style scoped>
.container {
  background-color: #0065bd;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cvut-logo {
  max-width: 450px;
  width: 60vw;
  margin-bottom: 3em;
}
ion-spinner {
  width: 3em;
  height: 3em;
}
.project-logo {
  position: absolute;
  bottom: 0;
  width: 100vw;
  max-width: 650px;
}
</style>
