import { $api } from "../apiClient";
const pathPrefix = "/network/event";

export default {
  getAllEvents(network_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    return $api.post(`${pathPrefix}/all`, payload);
  },
  getEventDetail(network_id, event_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("event_id", event_id);
    return $api.post(`${pathPrefix}/detail`, payload);
  },
  createNewEvent(network_id, date_start, date_end, name, description, img) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("date_start", date_start);
    payload.append("name", name);
    if (date_end.length > 0) {
      payload.append("date_end", date_end);
    }
    if (description.length > 0) {
      payload.append("description", description);
    }

    payload.append("image", img);

    return $api.post(`${pathPrefix}/create`, payload);
  },
  setInterest(network_id, event_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("event_id", event_id);

    return $api.post(`${pathPrefix}/interest`, payload);
  },
  setParticipate(network_id, event_id) {
    let payload = new FormData();
    payload.append("network_id", network_id);
    payload.append("event_id", event_id);

    return $api.post(`${pathPrefix}/participate`, payload);
  },
};
