import apiFactory from "../../api/apiFactory";
import _ from "lodash";

const SocialApi = apiFactory.get("social");

// initial state
const state = () => ({
  isLoaded: false,
  activeId: 0,
  activePosts: [],
  userPosts: [],
  socialList: [],
});

// getters
const getters = {
  getActiveId: (state) => state.activeId,
  getActivePosts: (state) => state.activePosts,
  getUserPosts: (state) => state.userPosts,
  getSocialList: (state) => state.socialList,
  isLoaded: (state) => state.isLoaded,
};

// actions
const actions = {
  resetCurrentSocialData({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("resetCurrentSocialData");
      dispatch("chat/resetChatListData", {}, { root: true });
      resolve("reseted");
    });
  },
  enterNetwork({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.addToNetwork(data.code)
        .then((res) => {
          // console.log("res data", res.data);
          dispatch("downloadListOfUsersSocial");
          dispatch("setActiveSocial", { id: res.data.data.network.id });
          resolve(res.data.data.network.id);
        })
        .catch((err) => {
          console.log("ERR IN", err);
          if (err.code == 404) {
            reject("Sociální síť s tímto kódem neexistuje");
          }
        });
    });
  },
  downloadSocialDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("changeLoadedStatus", { status: false });
      SocialApi.getSocialDetail(data.id)
        .then((res) => {
          commit("setPostsInActiveNetwork", { posts: res.data.data.post });
          commit("changeLoadedStatus", { status: true });
          resolve(res.data.data.post);
        })
        .catch((err) => {});
    });
  },
  downloadUserPosts({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("changeLoadedStatus", { status: false });
      SocialApi.getMyPosts(data.id)
        .then((res) => {
          commit("setUserPosts", { posts: res.data.data });
          commit("changeLoadedStatus", { status: true });
          resolve(res.data.data);
        })
        .catch((err) => {});
    });
  },
  downloadListOfUsersSocial({ commit }) {
    return new Promise((resolve, reject) => {
      SocialApi.getListOfSocials()
        .then((res) => {
          commit("setListOfUserSocials", { socials: res.data.data });
          resolve(res.data.data);
        })
        .catch((err) => {});
    });
  },
  leaveSocialNetwork({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.leaveSocial(data.network).then((res) => {
        // commit("removeFromSocialList", { id: data.network });
        dispatch("downloadListOfUsersSocial");
        if (state.activeId == data.network) {
          if (state.socialList.length > 0) {
            let socialId = state.socialList[0].id;
            commit("setActiveId", { id: socialId });
            dispatch("downloadSocialDetail", { id: socialId });
          } else {
            commit("setActiveId", { id: 0 });
          }
        }
        console.log("state", state);
        resolve("removed");
      });
    });
  },
  setActiveSocial({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setActiveId", { id: data.id });
      dispatch("downloadSocialDetail", { id: data.id });
      resolve(data.id);
    });
  },
  likePost({ commit }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.sendLike(data.network, data.post).then((res) => {
        resolve(res.data.data.countLikes);
      });
    });
  },
  likeComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.sendLikeComment(data.networkId, data.commentId).then((res) => {
        resolve(res.data.data.countLikes);
      });
    });
  },
  commentPost({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let groupId = null;
      let eventId = null;
      if (data.groupId) {
        groupId = data.groupId;
      }
      if (data.eventId) {
        eventId = data.eventId;
      }
      SocialApi.sendComment(data.networkId, data.postId, data.text).then(
        (res) => {
          if (groupId) {
            dispatch(
              "groups/downloadGroupDetail",
              { networkId: data.networkId, eventId: eventId },
              { root: true }
            );
          } else if (eventId) {
            dispatch(
              "event/downloadEventDetail",
              { networkId: data.networkId, groupId: groupId },
              { root: true }
            );
          } else {
            dispatch("downloadSocialDetail", { id: data.networkId });
          }

          resolve(res.data.data);
        }
      );
    });
  },
  createNewPost({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.sendNewPost(
        data.network,
        data.text,
        data.eventId,
        data.groupId,
        data.postImg
      ).then((res) => {
        resolve(res);
      });
    });
  },
  reciveNewPost({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setNewPost", { post: data.post });
    });
  },
  reciveLikePost({ commit, dispatch, getters }, data) {
    return new Promise((resolve, reject) => {
      // console.log("vuex", this);

      let activePosts = getters.getActivePosts;
      let userPosts = getters.getUserPosts;

      let index = _.findIndex(activePosts, (o) => {
        return o.id == data.post.id;
      });

      let userIndex = _.findIndex(userPosts, (o) => {
        return o.id == data.post.id;
      });

      let post = activePosts[index];
      post.likes = data.post.likes;

      commit("setPost", { index: index, post: post });
      commit("setUserPost", { index: userIndex, post: post });
    });
  },
  reciveComment({ commit, dispatch, getters }, data) {
    return new Promise((resolve, reject) => {
      let activePosts = getters.getActivePosts;
      let userPosts = getters.getUserPosts;

      let index = _.findIndex(activePosts, (o) => {
        return o.id == data.comment.post_id;
      });

      let userIndex = _.findIndex(userPosts, (o) => {
        return o.id == data.comment.post_id;
      });

      let comment = data.comment;
      comment["name"] = data.user.name;
      comment["avatar_id"] = data.user.avatar_id;
      comment["avatar_url"] = data.user.avatar_url;
      let post = activePosts[index];
      post.comments.push(data.comment);

      commit("setPost", { index: index, post: post });
      commit("setUserPost", { index: userIndex, post: post });
    });
  },
  reciveCommentLike({ commit, dispatch, getters }, data) {
    return new Promise((resolve, reject) => {
      let activePosts = getters.getActivePosts;
      let userPosts = getters.getUserPosts;

      let index = _.findIndex(activePosts, (o) => {
        return o.id == data.comment.post_id;
      });

      let userIndex = _.findIndex(userPosts, (o) => {
        return o.id == data.comment.post_id;
      });

      let commentIndex = _.findIndex(activePosts[index].comments, (o) => {
        return o.id == data.comment.id;
      });
      let likes = data.comment.likes;
      let post = activePosts[index];
      post.comments[commentIndex].likes = likes;

      commit("setPost", { index: index, post: post });
      commit("setUserPost", { index: userIndex, post: post });
    });
  },
  editUserPorfile({ commit, dispatch, getters }, data) {
    return new Promise((resolve, reject) => {
      SocialApi.updateProfile(data.network, data.name, data.avatar).then(
        (res) => {
          commit(
            "auth/setUserInfo",
            {
              avatarId: data.avatar,
              avatarUrl: data.avatarUrl,
              name: data.name,
              id: data.userId,
            },
            { root: true }
          );
          resolve(res);
        }
      );
    });
  },
};

// mutations
const mutations = {
  setActiveId(state, payload) {
    state.activeId = payload.id;
  },
  setPostsInActiveNetwork(state, payload) {
    state.activePosts = payload.posts;
  },
  setUserPosts(state, payload) {
    state.userPosts = payload.posts;
  },
  setListOfUserSocials(state, payload) {
    state.socialList = payload.socials;
  },
  changeLoadedStatus(state, payload) {
    state.isLoaded = payload.status;
  },
  setNewPost(state, payload) {
    state.activePosts.unshift(payload.post);
  },
  setPost(state, payload) {
    // console.log("pp", payload);
    state.activePosts.splice(payload.index, 1, payload.post);
  },
  setUserPost(state, payload) {
    // console.log("pp", payload);
    state.userPosts.splice(payload.index, 1, payload.post);
  },
  clearPosts(state, payload) {
    state.activePosts = [];
  },
  resetCurrentSocialData(state, payload) {
    state.activeId = 0;
    state.activePosts = [];
    state.userPosts = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
