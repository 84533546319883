import { forEach } from "lodash";
import apiFactory from "../../api/apiFactory";
const EventApi = apiFactory.get("events");

// initial state
const state = () => ({
  discoverEvents: [],
  userEvents: [],
  otherEvetns: [],
  detailEvent: {},
});

// getters
const getters = {
  getDiscoverEvents: (state) => state.discoverEvents,
  getUserEvents: (state) => state.userEvents,
  getOtherEvetns: (state) => state.otherEvetns,
  getEventPosts: (state) => state.detailEventPosts,
  getEventDetail: (state) => state.detailEvent,
};

// actions
const actions = {
  downloadAllEvents({ commit }, data) {
    return new Promise((resolve, reject) => {
      console.log("Downloading all events");
      EventApi.getAllEvents(data.networkId)
        .then((res) => {
          let data = res.data.data;
          console.log("all events:", res.data.data);
          commit("setEvents", {
            discover: data.discover,
            participate: data.participate,
            interest: data.interest,
          });
          resolve(data);
        })
        .catch((err) => {});
    });
  },
  downloadEventDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      EventApi.getEventDetail(data.networkId, data.eventId)
        .then((res) => {
          let data = res.data.data;
          console.log("detail:", res.data.data);
          commit("setEventDetail", {
            detail: data.event,
          });
          commit(
            "social/setPostsInActiveNetwork",
            { posts: data.posts },
            { root: true }
          );
          resolve("detail downloaded");
        })
        .catch((err) => {});
    });
  },
  createNewEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      EventApi.createNewEvent(
        data.networkId,
        data.startDate,
        data.endDate,
        data.name,
        data.descritpion,
        data.img
      )
        .then((res) => {
          let resData = res.data.data;
          console.log("Created Event:", resData, res);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },
  setInterest({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      EventApi.setInterest(data.networkId, data.eventId)
        .then((res) => {
          let resData = res.data.data;
          console.log("1");
          // dispatch("downloadEventDetail", { networkId: data.networkId });
          dispatch("downloadAllEvents", { networkId: data.networkId });
          dispatch("downloadEventDetail", {
            networkId: data.networkId,
            eventId: data.eventId,
          });

          console.log("2");
          resolve(resData);
        })
        .catch((err) => {});
    });
  },
  setParticipate({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      EventApi.setParticipate(data.networkId, data.eventId)
        .then((res) => {
          let resData = res.data.data;
          dispatch("downloadAllEvents", { networkId: data.networkId });
          dispatch("downloadEventDetail", {
            networkId: data.networkId,
            eventId: data.eventId,
          });
          resolve(resData);
        })
        .catch((err) => {});
    });
  },
  reciveNewEvent({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setNewEvent", { event: data.event });
      resolve();
    });
  },
};

// mutations
const mutations = {
  setEvents(state, payload) {
    state.discoverEvents = payload.discover;
    let userEvents = payload.participate.concat(payload.interest);
    state.userEvents = userEvents;
  },
  setEventDetail(state, payload) {
    state.detailEvent = payload.detail;
  },
  setNewEvent(state, payload) {
    state.discoverEvents.unshift(payload.event);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
