<template>
  <ion-app>
    <div class="loader" v-if="!loaded"><LoadingScreen></LoadingScreen></div>
    <div v-else>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </div>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
  toastController,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import { emitter } from "./eventBus";
import { chatbubble, chevronForward } from "ionicons/icons";
import { SplashScreen } from "@capacitor/splash-screen";
import LoadingScreen from "./views/LoadingScreen.vue";

export default {
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    LoadingScreen,
  },
  setup() {
    return {
      chatbubble,
    };
  },
  data() {
    return { loaded: false };
  },
  beforeCreate() {
    SplashScreen.hide();
  },
  mounted() {
    let self = this;
    this.$nextTick(function() {
      self.loadApp();
    });
    // emitter.on("notification", (data) => {
    //   console.log("notification recived");
    //   this.showNotification(data);
    // });
    // emitter.on("noificationCounter", (data) => {
    //   this.changeCounter(data.type);
    // });
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      getActiveId: "social/getActiveId",
    }),
  },
  methods: {
    ...mapActions({
      registerAnonym: "auth/registerAnonym",
      downloadQuizArray: "quiz/downloadQuizArray",
      websocketInit: "websocket/websocketInit",
      connectToNetworkChannel: "websocket/connectToNetworkChannel",
      connectToChatChannel: "websocket/connectToChatChannel",
      login: "auth/login",
      changeCounter: "notification/changeCounter",
    }),
    loadUser() {
      return new Promise((resolve, reject) => {
        const self = this;
        if (self.isLoggedIn.length > 0) {
          self.login({ email: self.isLoggedIn, pass: "" }).then((res) => {
            self
              .loadQuizes()
              .then((res) => {
                console.log("Quiz Succes");
                resolve("Succes");
              })
              .catch((err) => {
                console.log("Quiz failed");
                reject("Quiz array failed");
              });
          });
        } else {
          self
            .registerAnonym()
            .then((res) => {
              console.log("isLoggedIn", self.isLoggedIn);
              self
                .loadQuizes()
                .then((res) => {
                  resolve("Succes");
                })
                .catch((err) => {
                  reject("Quiz array failed");
                });
            })
            .catch((err) => {
              reject("Login failed");
            });
        }
      });
    },
    loadQuizes() {
      return new Promise((resolve, reject) => {
        const self = this;
        self
          .downloadQuizArray()
          .then((res) => {
            console.log("Download Quiz failed");
            resolve("Succes");
          })
          .catch((err) => {
            console.log("Download Quiz failed");
            reject("Quiz array failed");
          });
      });
    },
    loadApp() {
      let self = this;
      Promise.all([this.loadUser()])
        .then((value) => {
          if (self.getActiveId != 0) {
            self.connectToNetworkChannel({ id: self.getActiveId });
            self.connectToChatChannel({ id: self.getActiveId });
          }
          console.log("APP loaded succesfuly");

          setTimeout(() => {
            self.loaded = true;
          }, 1500);
        })
        .catch((err) => {
          // self.loaded = true;
          console.log("err", err);
          alert("APP load failed");
        });
    },
    async showNotification(data) {
      let self = this;

      let icon = "";
      let btnIcon = "";
      let btnText = "";
      let notifyHeader = "";
      let notifyText = "";
      let link = "";
      if (data.type == "message") {
        icon = chatbubble;
        btnText = "";
        btnIcon = chevronForward;
        notifyHeader = "Zpráva od " + data.from;
        notifyText = data.text;
        link = "/chat/" + data.linkId;
      }

      const toast = await toastController.create({
        header: notifyHeader,
        message: notifyText,
        position: "top",
        duration: 3000,
        buttons: [
          {
            side: "start",
            icon: icon,
            text: "",
          },
          {
            side: "end",
            icon: btnIcon,
            text: btnText,
            role: "cacnel",
            handler: (e) => {
              console.log("Redirect clicked");
              console.log("event", e, self.$route);
              self.$router.push(link);
            },
          },
        ],
      });
      // console.log("link", link, self.$route.fullPath);
      if (data.type == "message" && link == self.$route.fullPath) {
        return;
      }
      await toast.present();
      // toast.dismiss(3000);
    },
  },
};
</script>

<style>
:root {
  --ion-card-background: #fff;
  --ion-background-color: #f2f2f7;
  --ion-background-color: #fff;
}
ion-content {
  --ion-background-color: #f2f2f7;
}
ion-card {
  --ion-card-background: #fff;
  --ion-background-color: #fff;
}
ion-item {
  --ion-card-background: #fff;
  --ion-background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --ion-background-color: #1e1e1e;
    --ion-card-background: #121212;
    --ion-background-color: #1e1e1e;
  }
  ion-content {
    --ion-background-color: #121212;
  }
  ion-card {
    --ion-card-background: #1e1e1e;
    --ion-background-color: #1e1e1e;
  }
  ion-item {
    --ion-card-background: #1e1e1e;
    --ion-background-color: #1e1e1e;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media only screen and (min-width: 768px) and (min-height: 768px) {
  .sc-ion-modal-md-h {
    --width: 80vw;
    --height: 80vh;
  }
}
</style>
